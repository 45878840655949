/* styles.css */
.markdown-body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}
.markdown-body h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}
.markdown-body h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}
.markdown-body ul {
  padding-left: 1.5em;
}
.markdown-body a {
  color: #007bff;
  text-decoration: none;
}
.markdown-body a:hover {
  text-decoration: underline;
}
.markdown-body img{
  max-width: 100%;
  height: auto;
}