.react-pdf__Page {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  margin: 0 auto;
  border: 1px solid #e8e8e8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  font-family: initial;
  font-size: initial;
  color: initial;
  max-width: none;
  max-height: none;
  background-color: initial;
}
.pdf-viewer-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.pdf-viewer-header {
  background: #001529;
  padding: 0 20px;
  color: #fff;
}
.pdf-viewer-content {
  flex: 1;
  padding: 20px;
  background: #ffffff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdf-viewer-footer {
  text-align: center;
  background: #f0f2f5;
  padding: 10px 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}
.pdf-page-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}
.react-pdf__Page {
  max-width: 100%;
  max-height: 90vh;
}
.react-pdf__Page__textContent {
  display: none;
}
.react-pdf__Page__annotations {
  display: none;
}
/* Estilos para o componente PDF */
.react-pdf__Page {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  margin: 0 auto;
  border: 1px solid #e8e8e8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer; /* Adiciona um cursor de ponteiro para indicar que o PDF é clicável */
}
.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  font-family: initial;
  font-size: initial;
  color: initial;
  max-width: none;
  max-height: none;
  background-color: initial;
}
/* Layout geral */
.pdf-viewer-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.pdf-viewer-header {
  background: #001529;
  padding: 0 20px;
  color: #fff;
}
.pdf-viewer-content {
  flex: 1;
  padding: 20px;
  background: #ffffff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdf-viewer-footer {
  text-align: center;
  background: #f0f2f5;
  padding: 10px 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}
.pdf-page-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  transition: transform 0.3s ease; /* Adiciona transição suave para zoom */
}
.pdf-page-container.expanded {
  transform: scale(1.5); /* Ajuste o fator de escala conforme necessário */
  z-index: 1000; /* Garante que o PDF expandido apareça sobre outros conteúdos */
}
/* Botões */
.ant-btn {
  border-radius: 4px;
  padding: 0 16px;
  font-size: 14px;
}
.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #fff;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn[disabled] {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  color: #bfbfbf;
}
/* Estilos para o componente PDF */
.react-pdf__Page {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  margin: 0 auto;
  border: 1px solid #e8e8e8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer; /* Adiciona um cursor de ponteiro para indicar que o PDF é clicável */
}
.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  font-family: initial;
  font-size: initial;
  color: initial;
  max-width: none;
  max-height: none;
  background-color: initial;
}
/* Layout geral */
.pdf-viewer-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.pdf-viewer-header {
  background: #001529;
  padding: 0 20px;
  color: #fff;
}
.pdf-viewer-content {
  flex: 1;
  padding: 20px;
  background: #ffffff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pdf-viewer-footer {
  text-align: center;
  background: #f0f2f5;
  padding: 10px 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}
/* Container do PDF */
.pdf-page-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adiciona margem superior para separar o PDF dos botões */
}
/* Botões acima do PDF */
.pdf-viewer-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px; /* Adiciona margem inferior para separar os botões do PDF */
}
.pdf-viewer-controls .ant-btn {
  border-radius: 4px;
  padding: 0 16px;
  font-size: 14px;
}
.pdf-viewer-controls .ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #fff;
}
.pdf-viewer-controls .ant-btn-primary:hover,
.pdf-viewer-controls .ant-btn-primary:focus {
  background-color: #40a9ff;
  border-color: #40a9ff;
}
.pdf-viewer-controls .ant-btn[disabled] {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  color: #bfbfbf;
}