/* Estilize o container do input */
.input-container {
  position: relative;
}
/* Estilize o texto dentro do container */
.input-container label {
  position: absolute;
  top: -10px;
  /* Ajuste conforme necessário */
  left: 8px;
  /* Ajuste conforme necessário */
  background-color: white;
  /* Garante que o texto esteja sobre o input */
  padding: 0 5px;
  /* Adiciona algum espaçamento para melhor aparência */
  font-size: 12px;
  /* Ajuste conforme necessário */
  color: #777;
  /* Cor do texto */
  transition: top 0.3s, font-size 0.3s;
  /* Adiciona uma transição suave */
  border-radius: 20px;
}
/* Estilize o input */
.input-container input {
  width: 100%;
  padding: 10px;
  /* Ajuste conforme necessário */
  border: 1px solid #aaa;
}
/* Estilize o input quando estiver focado para mover o texto para cima */
.input-container input:focus+label,
.input-container input:not(:placeholder-shown)+label {
  top: -0px;
  /* Ajuste conforme necessário */
  font-size: 15px;
  /* Ajuste conforme necessário */
  color: #000000;
  font-weight: bolder;
}