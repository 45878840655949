.principal {
  width: 100%;
  font-family: "roboto";
}
.conteudo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 10px; 
}
.conteudo-frase {
  display: flex;
  justify-content: center;
}
.conteudo-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.conteudo-titulo {
  text-align: start;
  margin-bottom: 20px;
  border-top: 0.1px solid rgba(0, 0, 0, 0.07);
  padding-top: 20px; 
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px; 
  width: 95%; 
  margin: 0 auto;
  text-align: justify;
}
.conteudo-paragrafo {
  text-align: justify;
  text-indent: 15px; 
  font-size: 1.2rem; 
}
.card-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px; 
}
.card-titulo {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  padding-top: 20px;
  font-size: 1.2rem;
}
.card-titulo h3 {
  display: flex;
  justify-content: center;
  text-align: justify;
  width: 90%; 
}
@media screen and (max-width: 768px) {
  .conteudo-titulo,
  .container {
    padding-top: 10px;
    margin-top: 20px;
  }
  .conteudo-paragrafo {
    text-indent: 0;
    font-size: 1rem;
  }
  .card-titulo {
    padding-top: 10px;
    margin-top: 20px;
    font-size: 1rem;
  }
}
.container-card-wiki {
  overflow: auto !important;
  font-size: 100% !important;
}