@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
* {
    font-family: 'Roboto',  Arial, Helvetica;
}
.consulta-form-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2rem 0 0 0;
    gap: 1rem;
}
.consulta-form-field {
    display: flex;
    flex-direction: column;
}
.consulta-form-input {
    width: 100%;
    background: rgba(255, 255, 255, 0.35);
    color: #717171;
    height: 53px;
}
.consulta-form-name-field {
    height: 53px;
    padding: 20px;
}
.consulta-form-option {
    z-index: 1;
    width: 100%;
}
.consulta-form-date__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
}
.consulta-form-date {
    display: flex;
    flex-direction: column;
}
.consulta-form-date-input {
    box-sizing: border-box;
    width: 24rem;
    height: 53px;
    padding: 20px;
}
.consulta-form-hour-input {
    width: 24rem;
    height: 53px;
    padding: 20px;
}
#consulta-hour-input,
#consulta-date-input {
    margin-top: 8px;
}
.consulta-form-label {
    font-family: Roboto,  Arial, Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.consulta-form-input__observacoes,
.consulta-form-input__infoConsulta {
    height: 66px;
    padding: 20px;
}
.consulta-form-input__motivo {
    height: 257px;
    padding: 20px;
}
.consulta-form-input__motivo textarea {
    color: green;
}
.consulta-form-button {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin-top: 3rem;
}
.consulta-form-button__agendar {
    width: 300px;    
    height: 40px;
}
.consulta-form-button__cancelar {
    width: 300px;
    height: 40px;
    background: #557678;
    color: white;
    outline: none;
    border: none;
}
.consulta-form-button__cancelar:hover {
    background: #658e91;
    color: white;
    outline: none;
    border: none;
}
@media screen and (max-width: 1300px) {
    .consulta-form-date-input,
    .consulta-form-hour-input {
        width: 12rem;
    }
}
@media screen and (max-width: 700px) {
    .consulta-form-button__agendar,
    .consulta-form-button__cancelar {
        width: 200px;
    }
}
@media screen and (max-width: 550px) {
    .consulta-form-date__wrapper,
    .consulta-form-button {
        flex-direction: column;
    }
    .consulta-form-button {
        align-items: center;
        gap: 20px;
    }
    .consulta-form-button__cancelar {
        height: 40px;
    }
}