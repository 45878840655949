.dropzone {
    border: 2px dashed #aaaaaa;
    padding: 30px;
    cursor: pointer;
  }
  .dropzone p {
    margin: 0;
  }
  .uploaded-image {
    max-width: 100%;
    max-height: 200px;
    margin-top: 20px;
  }
  .response {
    text-align: left;
  }
  .response h2 {
    margin-top: 40px;
  }
  .image-preview-container {
    height: 200px;
    overflow-y: auto;
  }
  .captured-image-container {
    flex: 0 0 60px; /* Largura fixa de 60px para cada imagem */
  }
  .captured-image-wrapper {
    position: relative;
    width: auto;
    padding-bottom: 100%; /* Aspect ratio quadrado */
  }
  .captured-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .delete-image-button {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: red;
  }