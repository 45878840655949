.configuracao-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 50%;
}
.configuracao-main-content-title {
  margin-top: 20px;
  font-weight: 700;
}
.configuracao-main-content-input {
  height: 2.2rem;
  width: 30rem;
  outline: none;
  border-radius: 5px;
  background: #b1c1f1;
  box-shadow:  5px 5px 10px #96a4cd,
             -5px -5px 10px #ccdeff;
}
.configuracao-main-input-title {
  font-weight: 500;
}
.configuracao-btn-github,
.configuracao-btn-diretorio {
  height: 2.2rem;
}
.configuracao-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.input-analise{
 height: 2.2rem;
    width: 30rem;
    outline: none;
    border-radius: 5px;
    background: #b1c1f1;
    box-shadow: 5px 5px 10px #96a4cd, -5px -5px 10px #ccdeff;
}
.wrapper-analise{
  display:flex;
  align-items: center;
  gap: 10px;}
  .button-analisar{
    height: 2.2rem;
}
.analize-titulo{
text-align: center;
}
.results {
  margin-top: 20px; /* Espaço entre a entrada de texto/botão e os resultados */
  text-align: left; /* Alinhar o texto à esquerda */
  background-color: #f7f7f7; /* Cor de fundo para destacar a seção de resultados */
  padding: 10px; /* Espaçamento interno para os resultados */
  border-radius: 5px; /* Borda arredondada */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Sombra */
}
@media screen and (max-width: 768px) {
  .input-analise,
  .configuracao-main-content-input{
    width: 100%;
  }
}