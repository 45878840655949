.clients-carousel-container {
  width: 100%;
  margin: 0 auto;
}

.carousel-header {
  background-color: #334B90;
  padding: 30px 30px;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  /* Sombra sutil */
  margin-bottom: 10px;
  /* Espaço entre o título e o carrossel */
  position: absolute;
  border-radius: 50%;
  z-index: 2;
  /* Garante que o título fique acima do carrossel */
}

.carousel-wrapper {
  overflow: hidden;
  /* Impede que o carrossel ultrapasse a área visível */
  position: relative;
}

.client-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-top: 27px;
}

.client-item .ant-avatar {
  border-radius: 50%;
  /* Deixa a imagem redonda */
}