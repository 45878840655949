.container-table{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  box-shadow: 0 1px 14px rgba(0,0,0,.20);
  border-radius: 3px;
  width: fit-content;
}
.thead-table {
  width: 100%;
  background-color: #585858;
  padding: 10px 8px;
  border-radius: 3px 3px 0 0;
}
.tr-table{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}
.tbody-table {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.title-table{
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  padding: 0 8px;
}
.text-table{
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
}