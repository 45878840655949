.chart-configuration-container {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.chart-configuration-container h4 {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 400;
}
.chart-configuration-form {
  display: flex;
  flex-direction: column;
}
.chart-configuration-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.config-label {
  font-size: 14px;
  font-weight: 400;
  color: var(--ds-text, #5e6c84);
  margin-bottom: 6px;
  margin-left: 5px;
  display: flex;
  align-items: center;
}
.chart-configuration-required-field {
  color: red;
  margin-left: 4px;
}
.chart-configuration-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background-color: #fff;
}
.chart-configuration-button {
  color: var(--ds-text, #42526e);
  background: var(--ds-background-neutral, #f4f5f7);
  border: none;
  margin-right: 4px;
  height: 32px;
  min-width: 24px;
  border-radius: 3px;
  text-shadow: none;
  line-height: 32px;
  padding: 0 10px;
}
.save-button {
  background-color: #42526e;
  color: #fff;
}
.cancel-button {
  background-color: #e74c3c;
  color: #fff;
}
.chart-configuration-button:hover {
  background-color: #c1cee2;
}
.chart-display {
  margin: 0 18%;
}
/* Estilo padrão para input */
.chart-configuration-container select, .css-13cymwt-control,
.chart-configuration-container input {
  width: 100%;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  background-color: var(--ds-background-input, #f4f5f7);
  border: 1px solid var(--ds-border-input, #dfe1e6);
  padding: 4px 7px 5px;
  font-family: "apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", " Arial, Helvetica Neue",
    " Arial, Helvetica";
  transition: background-color 0.2s;
}
.chart-configuration-field select,.css-13cymwt-control,
option {
  color: var(--ds-text, #172b4d);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  color: var(--ds-text, #091e42);
}
.chart-configuration-container select:focus,.css-13cymwt-control:focus,
.chart-configuration-container input:focus {
  background-color: #fff;
}
.chart-configuration-container select:not(:focus), .css-13cymwt-control:not(:focus),
.chart-configuration-container input:not(:focus) {
  background-color: var(--ds-background-input, #f4f5f7);
}
.css-13cymwt-control,.css-13cymwt-control{
  padding: 0px !important;
  /* border-color: #506581 !important; */
  box-shadow:    #506581 !important;
}
.css-t3ipsp-control:hover, .css-13cymwt-control:hover, .css-t3ipsp-control:visited, .css-13cymwt-control:visited{
  box-shadow: 0 0 0 1px #506581 !important;
  border-color: #506581 !important;
  padding: 0px !important;
}