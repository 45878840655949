.scroll-to-top-container {
  position: fixed; /* Manter esta posição como "fixed" para permitir a animação */
  bottom: 20px;
  right: 20px;
  z-index: 999;
}
.scroll-to-top-button {
  box-shadow: inset 0px 4px 4px 4px var(--primary);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 24px;
  transition: background-color 0.3s;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  position: relative;
}
.scroll-to-top-button.visible {
  opacity: 1;
  visibility: visible;
}
.scroll-to-top-button:hover {
  background-color: var(--primary);
  color: #fff;
  animation: scrollAnimation 2s linear infinite;
}
@keyframes scrollAnimation {
  0% {
    top: 0;
  }
  100% {
    top: -100%; /* Mude para "-100%" para animação de baixo para cima */
  }
}
.scroll-to-top-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  opacity: 0.2;
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}