.EcosystemMenu-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
  }
  .EcosystemMenu-link {
    align-items: center;
    justify-content: center;
    margin: 10px;
    margin-top: 5px;
    text-decoration: none;
    color: #333; /* Cor do texto quando o ícone não está em hover */
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .EcosystemMenu-link:hover {
    transform: scale(1.1);
  }
  .EcosystemMenu {
    background-color: #0D47A1; /* Fundo branco para o ícone */
    border-radius: 50%;
    padding: 5px;
    font-size: 2em;
    color: #c5b3b3; /* Cor do ícone */
    transition: color 0.3s ease;
  }
  .EcosystemMenu-link:hover .EcosystemMenu {
    color: white; /* Cor do ícone ao passar o mouse */
  }
  .EcosystemMenu-link span {
    position: absolute;
    bottom: -28px; /* Ajusta a posição do texto em relação ao ícone */
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    border-radius: 5px;
    color: #333; /* Cor do texto */
    font-size: 0.9em;
    opacity: 0;
    transition: opacity 0.3s ease;
    white-space: nowrap;
  }
  .EcosystemMenu-link:hover span {
    opacity: 1; /* Torna o texto visível ao passar o mouse */
  }