.principal-emit { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 20px;
    background-color: #fff;
    border: solid black;
    margin-bottom: 20px;
}
.div-title1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 102%;
    border-bottom: 2px solid black;
}
.title1 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
}
.div-content1{
    display: flex;
    flex-direction: column;
    width: 103%;
    margin-top: 10px;
}
.div-crm{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}
.div-crm > * {
    flex-basis: 80%; 
}
.div-crm > *:last-child {
    flex-basis: 20%; 
}
.div-city{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}
.div-city > * {
    flex-basis: 80%; 
}
.div-city > *:last-child {
    flex-basis: 20%; 
}
.phone-data{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}
.phone-data > * {
    flex-basis: 70%; 
}
.phone-data > *:last-child {
    flex-basis: 30%; 
}
.div-logo{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
}
.div-logo > * {
    flex-basis: 60%; 
}
.div-logo > *:last-child {
    flex-basis: 40%; 
}
.div-title2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 103%;
    margin-top: 10px;
}