.circle-container {
  position: relative;
  width: 350px; /* Tamanho do círculo */
  height: 350px;
  border-radius: 50%;
  margin-left: auto; /* Move o círculo para a direita */
  margin-right: 0;
}


.circle-item img {
  width: 100%; /* Ajuste para ocupar todo o espaço */
  height: auto;
  border-radius: 50%;
}


/* Adiciona título central */
.circle-center-title {
  position: absolute;
  top: 45%;
  left: 67%;
  padding: 30px 30px;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: center;
  width: 120px;
  background-color: #4CAF50;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 2; /* Garante que fique acima dos itens */
}
.circle-container {
  position: relative;
  width: 350px; /* Tamanho do círculo */
  height: 350px;
  border-radius: 50%;
  margin-left: auto; /* Move o círculo para a direita */
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.circle-item {
  display: flex;
  align-items: center; /* Alinha a imagem e o texto no centro verticalmente */
  justify-content: flex-start; /* Alinha à esquerda */
  position: absolute;
}

.circle-item img {
  width: 70px; /* Tamanho da imagem */
  height: 70px;
  border-radius: 50%; /* Mantém a imagem redonda */
}

.circle-item .circle-text {
  position: absolute;
  margin-left: -130px; /* Espaço entre a imagem e o texto */
  font-size: 12px;
  font-weight: bold;
  color: #FFF;
  text-align: left;
  padding: 5px 10px;
  background-color: #f5f5f5; /* Cor de fundo do botão */
  border-radius: 5px;
  cursor: pointer; /* Estilo de botão */
}


.circle-item.item-0 { top: 0; left: 50%; transform: translateX(50%); }
.circle-item.item-1 { top: 20%; left: 20%; transform: translateX(50%) translateY(-50%); }
.circle-item.item-2 { top: 50%; left: 0; transform: translateY(-50%) translateX(100%); }
.circle-item.item-3 { bottom: 20%; left: 20%; transform: translateX(50%) translateY(50%); }
.circle-item.item-4 { bottom: 0; left: 50%; transform: translateX(50%); }
.circle-item.item-center {
  top: 50%;
  left: 115%;
  transform: translateY(-50%);
  width: 90px;
  height: 90px;
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 0;
}

.circle-container img {
  object-fit: contain;
}
