.titulo-sprint {
  font-weight: lighter;
  color: #585858;
  margin-bottom: 20px; 
}
.container-conteudo {
  width: 70%; 
  margin: 0 auto; 
}
.container-objetivos {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 18px;
}
.code-debito {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px; 
  font-family: monospace; 
}
.md-typeset__scrollwrap {
  margin: 1em -0.8rem;  
  touch-action: auto;
}
.md-typeset .admonition .md-typeset__scrollwrap,
.md-typeset details .md-typeset__scrollwrap {
  margin: 1em -0.6rem;
}
.md-typeset__table {
  display: inline-block;
  margin-bottom: 0.5em;
  padding: 0 0.8rem;
}
@media print {
  .md-typeset__table {
    display: block;
  }
}
html .md-typeset__table table {
  display: table;
  margin: 0;
  overflow: hidden;
  width: 100%;
}
.md-typeset .admonition .md-typeset__table,
.md-typeset details .md-typeset__table {
  padding: 0 0.6rem;
}
.md-typeset table:not([class]) {
  background-color: var(--md-default-bg-color);
  border-radius: 0.1rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05), 0 0 0.05rem rgba(0, 0, 0, 0.1);
  display: inline-block;
  font-size: 0.64rem;
  max-width: 100%;
  overflow: auto;
  touch-action: auto;
}
.md-typset__table td{
padding: 0.9375em 1.25em
}
@media screen and (max-width: 770px) {
  .container-conteudo {
    width: 100%;
    padding: 10px; 
    margin: 150px 0px;
  }
}