.cabecalho {
  height: auto;
  position: relative;
  padding: 1rem;
  background-color: #4a9ae9;
}
.content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.cabecalho-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cabecalho-img {
  width: 2rem;
  cursor: pointer;
}
.cabecalho-left > span {
  font-size: 1.5rem;
  color: #ffffff;
}
.cabecalho-img:hover {
  transform: scale(1.2);
  transition: 0.3s;
}
.cabecalho-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px; /* Adicionado para separar o menu */
}
.cabecalho-menu-item {
  font-size: 1rem;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  transition: 0.3s;
}
.cabecalho-menu-item:hover {
  color: antiquewhite;
  transform: scale(1.1);
}
.cabecalho-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px; /* Adicionado para separar o menu */
}
.cabecalho-input {
  width: 200%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5px;
  margin: 3px 0;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.5);
  transition: width 0.3s; /*foi adicionado uma transição de animação suave*/
}
.cabecalho-input input {
  background-color: transparent;
  border: 0;
  font-size: 0.8rem;
  color: #fff;
  outline: none;
  flex-grow: 1;
}
.cabecalho-button {
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 11px;
}
.cabecalho-button:hover {
  transform: scale(1.2);
}
.active-tab {
  color: #fff !important;
}
/* Media Queries */
@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;    
    background-color: #000080 !important;    
    width: 100%;
    left: 0;
    position:relative;
    top: 0;
  }
  .group {
    gap: 1rem;
  }
  .cabecalho-left {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
  }
  .cabecalho-img {
    width: 3rem;
  }
  .cabecalho-menu {
    justify-content: center;
    margin: 10px 0; 
  }
  .cabecalho-right {
    align-items: center;
    margin: 10px 0; 
  }
  .cabecalho-input {
    width: 100%;
    max-width: 300px;
  }
  .cabecalho-input input {
    font-size: 0.7rem;
  }
}
.cabecalho-input.expanded {
  width: 700px; /*Largura desejada quando expandido */
}