:root {
  --off-white: #fafafa;
}
.nav-sprints {
  position: fixed;
  top: 165px;
  left: 75%;
  width: 200px;
  height: 28vh;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}
.nav-logo {
  height: 60px;
  width: 60px;
}
.nav-logo:hover {
  cursor: pointer;
}
.nav .wiki-nav-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Alinhamento vertical no topo */
  margin: -26% -22% 0% 37%;
}
.nav-item {
  display: block;
  margin-top: 1rem;
  color: #333;
}
.nav-item:hover {
  color: black;
  cursor: pointer;
}
/* Resto dos estilos */
.n-dark {
  background: #333;
  color: white;
}
.n-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}
.main-sprints {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 51%;
  margin: 0 17% auto;
  max-width: 1200px;
  padding: 40px 0;
}
/* Media query para telas de tamanho médio (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
  .main-projeto {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    width: 90%; /* Permitindo algum espaçamento nas laterais para tablets */
    padding: 30px 0;
  }
}
/* Media query para telas maiores (desktops) */
@media (min-width: 768px) {
  .main-projeto {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    width: 64%;
    max-width: 1200px;
    padding: 40px 0;
  }
}
/*.nav-sprints.sticky {
  position: fixed;
  top: 115px;
  width: 100%;
  background-color: white; 
}*/
/* @media screen and (max-width: 768px) {
  .bg-primary{    
    flex-direction: column;
    background-color: #000080 !important;
    width: 100%;
    left: 0;
    position: fixed;
  }
} */