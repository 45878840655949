.container-list{
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .container-titulo-list{
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 1.5rem;
  }
  .titulo-list{
    margin-bottom: 5px;
    font-weight: 400;
    color: #012060 !important;
  }