@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 font-family: 'Roboto',  Arial, Helvetica;
}
.consulta-wrapper-Editar{
    width: 100%;
    display: flex;
    flex-direction: row;
}
 .consulta-container-Editar{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5rem;
 }
 .Atestado-Paciente-Editar{
        margin-top: 80px;
        background-color: #f3f3f3;
        border: 1px groove #ddd;
        border-radius: 20px;
        padding: 20px;
        text-align: center;
 }
 .Atestado-titulo-Editar{
font-size: 1.25rem;
    font-family: roboto;
    font-weight: bolder;
}
  .Atestado-Data-table-container-Editar {
    margin-left: 15px;
    border: 1px groove #ddd;
    width: 90%;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    margin-left: 5%;
    /* Altura fixa para o contêiner */
    height: 400px;
    /* Ativar rolagem vertical por padrão */
    overflow-y: auto;
    overflow-x: none;
  }
  /* Configurações para telas com largura máxima de 768 pixels */
  @media (max-width: 768px) {
    .Atestado-Data-table-container-Editar {
      width: 100%;
      margin-left: 0;
      /* Remova a barra de rolagem vertical */
      overflow-y: hidden;
    }
  }