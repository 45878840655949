@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
 font-family: 'Roboto',  Arial, Helvetica;
}
.consulta-wrapper {
 width: 100%;
 display: flex;
 flex-direction: row;
}
.consulta-container {
 display: flex;
 flex-direction: column;
 width: 100%;
 gap: 5rem;
}
@media screen and (max-width: 768px) {
    .consulta-wrapper {
        max-width: 100%;
    }
}