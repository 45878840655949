/* TextIA.css */
/* Estilo para o contêiner do input e do botão */
.input-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  /* Estilo para o input */
  .input-field {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  /* Estilo para o botão dentro do input */
  .generate-button {
    position: absolute;
    right: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 18px;
    color: #007bff;
  }
  .input-filled {
    border-color: #28a745;
  }