.principalCompradorId{ 
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 48%;
    height: 100%;
    margin-right: 2%;
    margin-left: -2%;
    border: solid black;
}
.principalCompradorId h2{
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
}
.containerCompradorDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 2%;
}
.crfP {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.crfP-70 {
    flex: 0 0 70%;
}
.crfP-30 {
    flex: 0 0 30%;
}
.cidadeUfF {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.cidadeUfF-70 {
    flex: 0 0 70%;
}
.cidadeUfF-30 {
    flex: 0 0 30%;
}