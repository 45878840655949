.wiki-bg-primary {
  background-color: #000080 !important;
}
@media screen and (max-width: 768px) {
  .wiki-bg-primary {
    flex-direction: column;
    width: 100%;
    left: 0;
    position:relative;
    bottom: 0;
  }
}