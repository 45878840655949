.principalProdutosDispen { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 20px;
    background-color: #fff;
    border: solid black;
    margin-bottom: 20px;
}
.div-title1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 102%;
    border-bottom: 2px solid black;
}
.title1 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
}
.containerDadosMedDiv{
    display: flex;
    flex-direction: column;
    width: 103%;
    margin-top: 10px;
}