.dadosDoPaciente {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
    margin: 0 1rem;
    background-color: #a8a4a481;
}
.containersCompradorFornecedor{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 2%;
}
.verso-folha {
    page-break-before: always;
    margin-top: 5%;
  }
.div-title3{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
    width: 100%;
    border: solid black 3px;
}