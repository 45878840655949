.CardProdutos {
  height: 100%;
  background-color: silver;
  background: radial-gradient(ellipse at top, rgb(145, 145, 145) 50%, rgb(92, 91, 91) 100%);
  border-radius: 20px;
}
.sphere {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cylinder {
  width: auto;
  height: auto;
  border-radius: 15px;
  background-color: #ccc;
}
.tab-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #333;
}
.efeitoTridimencionalProdutos {
  box-shadow:  0.8em  0.8em 0.7em  0.8em #151515;
  }