.container-agenda {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, Arial, Helvetica,
    Arial, Helvetica, Arial, Helvetica;
}
.container-agenda input[type="text"] {
  text-align: center;
  font-family: bold;
  width: 100%;
}
.container-agenda {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, Arial, Helvetica,
    Arial, Helvetica, Arial, Helvetica;
}
.container-agenda input[type="text"] {
  text-align: center;
  font-family: bold;
  width: 100%;
}
*label {
  display: block;
  margin-bottom: 5px;
}
.react-calendar {
  position: relative;
  width: 100%;
  background-color: #0d0471;
  margin-top: -10px;
  color: #f2efef;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(245, 195, 163, 0.738);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, Arial, Helvetica,
    Arial, Helvetica, Arial, Helvetica;
  line-height: 4em;
}
.weekday {
  display: flex;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fdfcfc;
}
@media (max-width: 768px) {
  .weekdays {
    padding: 0 30px;
    /* ajuste o padding para telas menores */
  }
}
@media (max-width: 480px) {
  .weekdays {
    font-size: 12px;
    /* ajuste o tamanho da fonte para telas menores */
  }
}
.react-calendar__navigation button {
  color: #ffffff;
  min-width: 40px;
  background: #0d0471;
  font-size: 18px;
  display: inline;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #b1aeae;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
.label-agenda {
  display: block;
  margin-bottom: 5px;
}
.react-calendar {
  position: relative;
  align-items: center;
  width: 100%;
  background-color: #0d0471;
  margin-top: 5px;
  color: #f2efef;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(227, 216, 209, 0.738);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, Arial, Helvetica,
    Arial, Helvetica, Arial, Helvetica;
  line-height: 4em;
}
.weekdays {
  position: absolute;
  margin-top: 165px;
  background-color: #4a9ae9;
  display: flex;
  flex-wrap: wrap;
  width: 91%;
  height: 8%;
  justify-content: space-between;
  padding: 0 10%;
  font-size: 16px;
  font-weight: bold;
  color: #f2efef;
}
.calendar-container {
  min-width: 100%;
  text-align: center;
}
.weekday {
  display: flex;
  margin-top: 20px;
  font-size: 16px;
  align-items: center;
  font-weight: bold;
  color: #fdfcfc;
}
@media (max-width: 768px) {
  .weekdays {
    padding: 0 25px;
    /* ajuste o padding para telas menores */
  }
}
@media (max-width: 480px) {
  .weekdays {
    font-size: 14px;
    /* ajuste o tamanho da fonte para telas menores */
  }
}
/* .react-calendar__navigation button {
  color: #ffffff;
  min-width: 50px;
  background: #0d0471;
  font-size: 20px;
  margin-top: 20px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #b1aeae;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
} */
/* Botões de navegação do calendário */
.react-calendar__navigation button {
  color: #ffffff;
  /* Cor do texto dos botões (branco) */
  min-width: 50px;
  /* Largura mínima dos botões */
  background: #0d0471;
  /* Cor de fundo dos botões (azul) */
  font-size: 20px;
  text-align: center;
  /* Tamanho da fonte dos botões */
  margin-top: 20px;
  /* Margem superior dos botões */
  border: none;
  /* Remova as bordas dos botões */
  cursor: pointer;
  /* Altere o cursor para indicar que os botões são clicáveis */
  padding: 8px 10px;
  /* Espaçamento interno dos botões (vertical e horizontal) */
  border-radius: 4px;
  /* Cantos arredondados dos botões */
}
/* Estilos quando os botões de navegação estão habilitados e o cursor está sobre eles */
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #b1aeae;
  /* Cor de fundo quando hover ou foco (cinza claro) */
}
/* Estilos quando os botões de navegação estão desabilitados */
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
  /* Cor de fundo quando desabilitados (cinza mais claro) */
  color: #ccc;
  text-align: center;
  /* Cor do texto quando desabilitados (cinza) */
  cursor: not-allowed;
  /* Cursor indicando que os botões estão desabilitados */
}
abbr[title] {
  text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  color: rgb(154, 154, 154);
}
.react-calendar__tile {
  position: relative;
  flex-basis: calc(100% / 7);
  text-align: center;
  border: 1px solid #ffffff;
  /* Adicione uma borda para separar os dias */
  padding: 5px;
}
.react-calendar__tile--now {
  position: relative;
  background: #f2efef;
  color: #080707;
  z-index: 1;
}
.react-calendar__tile--now::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  background: rgb(193, 193, 193);
  /* cor da bolinha */
  width: 3rem;
  height: 3rem;
  padding-top: 0.25rem;
  display: grid;
  place-items: center;
  font-size: 1.5rem;
  z-index: -1;
}
.add-button {
  background-color: #f8f5f5;
  color: #21067b;
  border-radius: 5px;
  font-family: Arial, Arial, Helvetica, Arial, Helvetica;
  width: 200px;
  padding: 10px 20px;
}
.submit-button {
  background-color: #61aeed;
  transition-duration: 0.4s;
  border-radius: 5px;
  text-shadow: none;
  font-family: Arial, Arial, Helvetica, Arial, Helvetica;
  width: 100px;
  padding: 10px 20px;
  margin-left: 15px;
}
.submit-button:hover {
  background-color: #f7f7f7;
  color: #101011;
}
.remove-button {
  background-color: #f00f0f;
  color: #101011;
  transition-duration: 0.4s;
  border-radius: 5px;
  font-family: Arial, Arial, Helvetica, Arial, Helvetica;
  width: 100px;
  height: 45px;
  margin: 15px 0 15px 0;
}
.remove-button:hover {
  background-color: #ffffff;
  color: #0412c9;
  background-color: #ffffff;
  color: #df0505;
}
.endTime-button {
  margin-block-end: 15px;
  margin-left: 110px;
}
select {
  width: 200px;
  padding: 20px;
  font-size: 18px;
  border: 1px solid #322f2f;
  color: rgb(73, 27, 165);
}
.periodo {
  background-color: #ecf1f4;
  color: #101011;
  transition-duration: 0.4s;
  border-radius: 5px;
  width: 150px;
  height: 45px;
}
.send {
  margin-top: 10px;
  background-color: #5cb8fa;
  color: #101011;
  transition-duration: 0.4s;
  border-radius: 5px;
  font-family: Arial, Arial, Helvetica, Arial, Helvetica;
  width: 100px;
  padding: 10px 20px;
}
.react-calendar__month-view__weekdays {
  background-color: #4a9ae9;
  text-align: center;
}
.agenda-input-container {
  margin-top: -160px;
  max-height: 100px;
}
/*   
.modal-content {
  width: 650px;
  height: 300px;
  margin: auto;
  left: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 10px #D3D3D3;
}
.overlay-custom {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
*/