.close-button {
    width: 30px;
    height: 30px;
    background-color: #eb2a1c;
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-left: auto;
    margin-top: 170px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .close-button:hover {
    background-color: #e66262;
  }
  .button1 {
    background-color: #4CAF50; 
    border: none;
    color: white;
    padding: 16px 32px;
    width: 30%;
    height: 20px;
    text-align: center;
    align-items: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 10px;
  }
  .button1 {
    background-color: rgb(191, 225, 244); 
    color: black; 
    border: 2px solid #1a24e2;
  }
  .button1:hover {
    background-color: #2fbee5;
    color: white;
  }
  /* modal.css */
.button-style {
  display: flex;
  background-color: #32CD32;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  text-align: center;
  position: absolute;
  top: 80px;
  left: 33%;
  justify-content: center;
  outline: 0;
  margin-top: 10px;
  margin: 0;
  padding: 10px;
}
.button-style:hover {
  background-color: #2fbee5;
}
.select-timedual {
  width: 25%;
  padding: 5.2px;
  font-size: 16px; /* Ligeiramente reduzi o tamanho da fonte para maior legibilidade */
  border: 2px solid #322f2f; /* Aumentei a largura da borda para dar mais destaque */
  border-radius: 5px; /* Adicionei cantos arredondados para um visual mais suave */
  color: rgb(35, 34, 35);
  margin-right: 15px;
}
.input-paciente,.input-procedimentos {
  width: 200px;
  padding: 3px;
  font-size: 16px; /* Ligeiramente reduzi o tamanho da fonte para maior legibilidade */
  border: 2px solid #322f2f; /* Aumentei a largura da borda para dar mais destaque */
  border-radius: 5px; /* Adicionei cantos arredondados para um visual mais suave */
  color: rgb(35, 34, 35);
  margin-right: 15px;
}
.input-paciente::placeholder, .input-procedimentos::placeholder {
  color: #777; /* Cor do placeholder para torná-lo menos proeminente */
}
/* .input-container{
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 10px; 
} */