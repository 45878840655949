/* DropDown */
/* Estilos para a navegação */
.chart-dropdown-navigation {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 60px;
    background: #fff;  
    display: flex;
    justify-content: space-between;
    transition: height 0.5s, width 0.5s;
    transition-delay: 0s, 0.75s;
    overflow: hidden;
  }
  .chart-dropdown-navigation.active {
    width: 85%;
    height: 214px;
    transition: width 0.1s, height 0.1s;
    transition-delay: 0.1s, 0.1s;
    z-index: 1001;
  }
  .chart-dropdown-navigation .userBx {
    position: relative;
    height: 60px;
    background: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: 0.5s;
    transition-delay: 0s;
  }
  .chart-dropdown-navigation.active .userBx {
    width: calc(100% - 60px);
    transition-delay: 0s;
  }
  .chart-dropdown-navigation .userBx .username {
    white-space: nowrap;
    color: #555;
    font-size: 1.1em;
  }
  .chart-dropdown-navigation .userBx .imgBx {
    position: relative;
    min-width: 60px;
    height: 60px;
    overflow: hidden;
    background: #000;
    border-radius: 50%;
    border: 10px solid #fff;
  }
  .chart-dropdown-navigation .userBx .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /* Ícone do menu de hambúrguer */
  .chart-dropdown-navigation .chart-dropdown-menuToggle {
    position: relative;
    width: 90px;
    height: 60px;
    background: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chart-dropdown-navigation .chart-dropdown-menuToggle::before {  
    position: absolute;
    width: 32px;
    height: 2px;
    background: #555;
    transform: translateY(-10px);
    box-shadow: 0 10px #555;
    transition: 0.5s;
  }
  .chart-dropdown-navigation .chart-dropdown-menuToggle::after {  
    position: absolute;
    width: 32px;
    height: 2px;
    background: #555;
    transform: translateY(10px);
    transition: 0.5s;
  }
  .chart-dropdown-navigation.active .chart-dropdown-menuToggle::before {
    transform: translateY(0px) rotate(45deg);
    box-shadow: 0 0 #555;
  }
  .chart-dropdown-navigation.active .chart-dropdown-menuToggle::after {
    transform: translateY(0px) rotate(-45deg);
  }
  .chart-dropdown-menu {
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 60px;
    padding: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .chart-dropdown-menu li {
    list-style: none;
  }
  .chart-configuration-dropdown-item { 
    display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      flex-direction: column;
      -webkit-box-flex: 1;
      flex-grow: 1;
      line-height: var(--ds-font-lineHeight-100, 16px);
      outline: none;
      text-align: left;
      color: #6B778C;
  }
  .chart-excluir-dropdown-item { 
    display: flex;
    box-sizing: border-box;
    gap: 10px;
    margin: 20px 0;
    font-size: 1em;
    text-decoration: none;
    transition: color 0.3s;  
    margin: 20px 0;  
    -webkit-box-align: center;
    align-items: center;
    border: 0px;
    font-size: var(--ds-font-size-100, 14px);
    outline: 0px;
    text-decoration: none;
    user-select: none;
    padding-block: var(--ds-space-100, 8px);  
    background-color: var(--ds-background-neutral-subtle, transparent);
    color: currentcolor;
    cursor: pointer;
  }
  .chart-excluir-dropdown-item:hover {
    background-color: #F4F5F7; 
  }