@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');
* {
    font-family: 'Roboto',  Arial, Helvetica;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.consulta-agendar--wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.consulta-agendar-block {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.consulta-agendar--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.consulta-agendar--title {
    display: flex;
    height: 4rem;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    margin: 6rem 0 0 0;
    background: #D9D9D9;
    border-radius: 50px;
}
.consulta-agendar--text {
    margin-bottom: 0;
    font-size: 1.875rem;
    font-weight: 400;
    color: #000000;
    font-family: Roboto,  Arial, Helvetica;
}
.consulta-agendar-form {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 2rem 0 0 0;
    border: 1px solid #D9D9D9;
    padding: 30px;
}
.consulta-form-title {
    text-align: center;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}