.custom-modal .ant-modal-content {
    color: black;
 border-radius: 12px;
}
.custom-modal .ant-modal-header {
background-color: rgba(0, 0, 0, 0.6);
color: white;
border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.custom-modal .ant-modal-body {
 max-height: 80vh; /* Evita rolagem excessiva */
 overflow-y: auto;
}
.custom-modal .ant-modal-close {
 background:white;
 color: red;
   transition: transform 0.3s ease-in-out;
      border-radius:50%
}  
.custom-modal .ant-modal-close:hover {
transform: rotate(180deg);
background:white;
}
.highlighted {
 border: 2px solid red;
 border-radius: 5px;
 padding: 2px;
 animation: blink 1s infinite;
}
@keyframes blink {
 0% { opacity: 1; }
 50% { opacity: 0; }
 100% { opacity: 1; }
}