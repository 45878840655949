/* Exemplo de CSS para o componente FullScreenImage */
.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
  }
  .fullscreen__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .fullscreen__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .fullscreen__content img {
    max-width: auto;
    max-height: auto;
    object-fit: cover;
  }
  .fullscreen__content h2 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px;
  }
  .fullscreen__content button {
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
/* ImageDragDrop.css */
/* ImageDragDrop.css */
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-image-container {
  margin-bottom: 20px;
}
.main-image {
  max-width: 100%;
  height: auto;
  max-height: 300px;
  display: block;
  margin: 0 auto;
  padding: 10px;
}
.small-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.small-image-wrapper {
  width: 25%;
  padding: 5px;
}
.small-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
/* ImageDragDrop.css */
.hidden-input {
  display: none;
}
/* Resto dos estilos... */