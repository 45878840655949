.main-projeto {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  padding-bottom: 50%;
}
/* Media query para telas de tamanho médio (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
  .main-projeto {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    width: 90%; /* Permitindo algum espaçamento nas laterais para tablets */
    padding: 30px 0;
    padding-bottom: 50%;
  }
}
/* Media query para telas maiores (desktops) */
@media (min-width: 768px) {
  .main-projeto {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    width: 64%;
    max-width: 1200px;
    padding: 40px 0;
    padding-bottom: 50%;
  }
}