.Sprints {
  color: black; /* Altere a cor conforme necessário */
}
.ml-22 {
  color: black; /* Cor preta */  
}
.circle-icon {
  font-size: 0.5em; /* Defina o tamanho desejado para os ícones de círculo */
}
.sprint-align-right {
  position: relative;
  right: 0;   
}
/* SprintNavLoadData.css */
.sprint-nav-left {
  position: relative; 
  /*right: 30%;*//* Alinha o componente à esquerda */
  /* Outros estilos necessários... */
}
@media (max-width: 1110px) { /* Ajuste o valor conforme necessário */
  .nav-title,.nav-items{
    display: none; /* Oculta a seção de sprint em telas menores */
  }
}
@media (max-width: 1220px){ /* Ajuste o valor conforme necessário */
  .ml-22, .text-black,.titulo-menuitem,.wiki-nav-content{
    display: none; /* Oculta a seção de sprint em telas menores */
  }
}
  /* Estilos para telas menores */
@media (max-width: 768px) { /* Ajuste o valor conforme necessário */
  .ml-22, .text-black,.titulo-menuitem{
    display: none; /* Oculta a seção de sprint em telas menores */
  }
}