.container-card-sprintContent {
  background-color: var(--md-default-bg-color);
  border-radius: 0.1rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05), 0 0 0.05rem rgba(0, 0, 0, 0.1);
  display: flex; 
  max-width: 100%;
  width: 550px;  
  touch-action: auto;
  font-size: 60%; 
}
.container-card:hover {
  background-color: #ecf4fb;
}
.card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 183px;
  border: none;
  background-color: unset;
}
.img-card {
  border-radius: 50%;
  width: 90px;
  cursor: pointer;
}
.texto-card {
  text-align: center;
  font-family: "roboto";
  color: #012060;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
}
.texto-card:hover {
  color: #4a9ae9;
}
/* Media Queries */
@media screen and (max-width: 768px) {
  .container-card {
    padding: 1rem;
    gap: 20px;
  }
  .card {
    flex-basis: calc(50% - 15px);
    min-width: auto;
  }
  .texto-card {
    font-size: 0.9rem;
  }
}