.scene {
  margin: 40px 0;
  position: relative;
  width: 210px;
  height: 440px;
  perspective: 1000px;
  transform: '10deg';
}
.carousel3D {
  width: 100%;
  height: 100%;
  position: absolute; 
  transform: translateZ(-288px);
  transform-style: preserve-3d;
  transition: transform 1s;
}
.carousel3D__cell {
  border: 2px solid black;
  position: absolute;
  width: 190px;
  height: auto;
  left: 10px;
  top: 10px; 
  font-weight: bold;
  color: white;
  text-align: center;
}
.carousel3D__cell:nth-child(9n+1) { background:white; }
.carousel3D__cell:nth-child(9n+2) { background:white; }
.carousel3D__cell:nth-child(9n+3) { background:white; }
.carousel3D__cell:nth-child(9n+4) { background:white; }
.carousel3D__cell:nth-child(9n+5) { background:white; }
.carousel3D__cell:nth-child(9n+6) { background:white; }
.carousel3D__cell:nth-child(9n+7) { background:white; }
.carousel3D__cell:nth-child(9n+8) { background:white; }
.carousel3D__cell:nth-child(9n+0) { background:white; }
.carousel3D__cell:nth-child(1) { transform: rotateY(  0deg) translateZ(288px); }
.carousel3D__cell:nth-child(2) { transform: rotateY( 40deg) translateZ(288px); }
.carousel3D__cell:nth-child(3) { transform: rotateY( 80deg) translateZ(288px); }
.carousel3D__cell:nth-child(4) { transform: rotateY(120deg) translateZ(288px); }
.carousel3D__cell:nth-child(5) { transform: rotateY(160deg) translateZ(288px); }
.carousel3D__cell:nth-child(6) { transform: rotateY(200deg) translateZ(288px); }
.carousel3D__cell:nth-child(7) { transform: rotateY(240deg) translateZ(288px); }
.carousel3D__cell:nth-child(8) { transform: rotateY(280deg) translateZ(288px); }
.carousel3D__cell:nth-child(9) { transform: rotateY(320deg) translateZ(288px); }
@media (min-width: 768px) {
  .carousel3D {
      width: 200%;
      height: 400px;
  }
}