@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:ital@0;1&family=Oswald:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'roboto';
}
a {
    all: unset;
}
.hidden{
  display: none !important ;
}