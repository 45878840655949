.trl-container {
    background: linear-gradient(to right, #0D47A1, #203A43, #180a59);
    color: #fff;
    font-family: 'Arial', sans-serif;
  }
  .iconTRL-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
  }
  .iconTRL-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    text-decoration: none;
    color: #333; /* Cor do texto quando o ícone não está em hover */
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .iconTRL-link:hover {
    transform: scale(1.1);
  }
  .iconTRL {
    background-color: #0D47A1; /* Fundo branco para o ícone */
    border-radius: 50%;
    padding: 5px;
    font-size: 2em;
    color: #c5b3b3; /* Cor do ícone */
    transition: color 0.3s ease;
  }
  .iconTRL-link:hover .iconTRL {
    color: white; /* Cor do ícone ao passar o mouse */
  }
  .iconTRL-link span {
    position: absolute;
    bottom: -30px; /* Ajusta a posição do texto em relação ao ícone */
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    border-radius: 5px;
    color: #333; /* Cor do texto */
    font-size: 0.9em;
    opacity: 0;
    transition: opacity 0.3s ease;
    white-space: nowrap;
  }
  .iconTRL-link:hover span {
    opacity: 1; /* Torna o texto visível ao passar o mouse */
  }
  .trl-title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  .trl-levels {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .trl-level {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    width: 200px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .trl-level:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  .trl-number {
    font-size: 2.5em;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .trl-description {
    font-size: 1em;
    line-height: 1.5;
  }
  .trl-number-1 { background-color: #ff4c4c; color: #fff; } /* Vermelho escuro */
  .trl-number-2 { background-color: #ff6b6b; color: #fff; } /* Vermelho */
  .trl-number-3 { background-color: #ff8c8c; color: #000; } /* Rosa claro */
  .trl-number-4 { background-color: #ffb6b6; color: #000; } /* Rosa pálido */
  .trl-number-5 { background-color: #ffd9d9; color: #000; } /* Branco rosado */
  .trl-number-6 { background-color: #d9ffb6; color: #000; } /* Verde pálido */
  .trl-number-7 { background-color: #b3ff66; color: #000; } /* Verde claro */
  .trl-number-8 { background-color: #66ff66; color: #000; } /* Verde médio */
  .trl-number-9 { background-color: #00ff00; color: #000; } /* Verde escuro */