.CardServicos {
    height: 100%;
    background: radial-gradient(ellipse at top, #5cc76a 0%, #075b07 100%);
    border-radius: 20px;
}
.Absolute{
    height: auto;
    width: auto;
}
.holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.holder img,
.holder h1 {
    position: absolute;
    font-family:  Arial, Helvetica;
    text-align: center;
}
.efeitoTridimencionalServicos {
    box-shadow: 0.8em  0.8em 0.7em  0.8em #052401
  }
  .IAcommenting {
    opacity: 0;
  animation: IAcommenting 2s linear infinite;
}
@keyframes IAcommenting {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-150%);
  }
}
.regulacomment{
   width: auto;
}
.balao {
    position: relative;
    margin-left: calc(50% - 30px); /* margem inicial */
    margin-bottom: '40px';
  }
  .balao svg {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    fill: #fff;
  }
  .balao h5 {
    position: absolute;
  }
  @media (max-width: 1100px) {
    .balao {
        margin-left: calc(20% - 1px - ((1000px - min(100vw, 1100px)) / 20000000000 )); /* margem para tela entre 800 e 1200px */
    }
    .regulacomment{
        margin-bottom: '40px';
     }
  }
  @media (max-width: 1000px) {
    .balao {
        margin-left: calc(60% - 20px - ((750px - min(100vw, 1000px)) / 20000000000 ));/* margem para tela entre 800 e 1200px */
        margin-bottom:calc(60% - 20px - ((750px - min(100vw, 1000px)) / 20000000000 ));
    }
  }
  @media (max-width: 750px) {
    .balao {
        margin-left: calc(70% - 20px - ((586px - min(100vw, 750px)) / 20000000000 ));/* margem para tela entre 800 e 1200px */
        margin-bottom:calc(80% - 20px - ((586px - min(100vw, 750px)) / 20000000000 ));
    }
  }
    @media (max-width: 586px) {
    .balao {
        margin-left: calc(70% - 20px - ((400px - min(100vw, 586px)) / 20000000000 ));/* margem para tela entre 800 e 1200px */
        margin-bottom:calc(110% - 20px - ((400px - min(100vw, 586px)) / 20000000000 ));
    }
  }
  @media (max-width: 400px) {
    .balao {
        margin-left: calc(90% - 20px - ((200px - min(100vw, 400px)) / 20000000000 ));/* margem para tela entre 800 e 1200px */
        margin-bottom:calc(130% - 20px - ((200px - min(100vw, 400px)) / 20000000000 ));
    }
  }