::-webkit-scrollbar {
    width: 8px; /* Largura da barra de rolagem */
  }
  /* Estilizar o rastreamento (track) da barra de rolagem */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Cor de fundo do rastreamento */
  }
  /* Estilizar o "pulso" (thumb) da barra de rolagem */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor do pulso da barra de rolagem */
    border-radius: 4px; /* Cantos arredondados do pulso da barra de rolagem */
  }
  /* Mudar a cor do pulso quando estiver passando o mouse sobre ele */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  @media (max-width: 1010px) {
    .Atestado-Periodo .time {
      width: 100% !important;
    }
  }
  @media (max-width: 1010px) {
    .Atestado-campo-CID .CID{
      width: 100% !important;
    }
  }
  @media (max-width: 1010px) {
    .Atestado-campo-rg .RG{
      width: 100% !important;
    }
  }
  @media (max-width: 1010px) {
    .Atestado-campo-Data-de-Consulta .Data{
      width: 100% !important;
    }
  }