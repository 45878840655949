.toggle-switch {
    width: 60px;
    height: 30px;
    border-radius: 15px;
    background-color: #ccc;
    position: relative;
    cursor: pointer;
  }
  .toggle-switch-track {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.2s ease;
  }
  .toggle-switch-handle {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 0.2s ease;
  }
  .toggle-switch.on .toggle-switch-track {
    transform: translateX(30px);
    background-color: green;
  }
  .toggle-switch.on .toggle-switch-handle {
    transform: translateX(30px);
  }
  .toggle-switch.off .toggle-switch-track {
    transform: translateX(0);
    background-color: red;
  }
  .toggle-switch.off .toggle-switch-handle {
    transform: translateX(0);
  }