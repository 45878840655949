.color-palette {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.color-ball {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 12px;
}
.color-label {
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}
.color-palette-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    margin: 20px;
}
.color-example {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}
.color-box {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.color-box .color-label {
    color: #000000;
    transform: rotate(-15deg); /* Letras anguladas para dispositivos móveis */
}
.primary {
    background-color: #0A66C2;
}
.secondary {
    background-color: #0D47A1;
}
.accept {
    background-color: #4CAF50;
}
.accent {
    background-color: #FFC107;
}
.complementary {
    background-color: #FFFFFF;
}
.neutral {
    background-color: #F5F5F5;
}
.color-description {
    margin-top: 20px;
    text-align: center;
    max-width: 300px;
}
.color-name {
    margin-bottom: 10px;
}
.color-usage {
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
}
/* Media query para dispositivos móveis */
@media (max-width: 600px) {
    .color-label {
        transform: rotate(-15deg); /* Letras anguladas para baixo */
        font-size: 12px; /* Ajuste do tamanho da fonte */
    }
    .color-box {
        width: 120px;
        height: 120px;
    }
    .color-example {
        margin-bottom: 20px;
    }
    .color-description {
        font-size: 12px;
    }
}