/* .container-menuitem {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 15px;
}
.container-menuitem h3 {
  font-weight: bold;
}
.container-menuitem a {
  all: unset !important;
}
.titulo-menuitem {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}
.ordenado-container-menuitem {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.link-nav-tabcontent {
  font-size: 15px;
  width: 200px;
  text-align: start;
  margin-left: 16px;
}
.link-nav-tabcontent:hover {
  color: #4a9ae9 !important;
  cursor: pointer;
}
.lista-container-menuitem {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.lista-container-menuitem .titulo-menuitem,
.itens-lista-menuitem {
  cursor: pointer;
}
li.titulo-menuitem:hover {
  color: #4a9ae9 !important;
}
.itens-lista-menuitem :hover {
  color: #4a9ae9 !important;
}
.itens-lista-menuitem {
  list-style-type: none;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.texto-itens-menuitem {
  font-size: 14px;
}
.icon-arrow {
  transform: rotate(90deg);
  transition: .4s;
} */
.container-menuitem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
}
.container-menuitem h3 {
  font-weight: bold;
}
.container-menuitem a {
  all: unset !important;
}
.titulo-menuitem {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ajuste a largura conforme necessário */
  max-width: 200px; /* Adicione uma largura máxima */
}
.ordenado-container-menuitem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1.5rem;
}
.link-nav-tabcontent {
  font-size: 15px;
  width: 100%; /* Ajuste a largura conforme necessário */
  text-align: start;
  margin-left: 16px;
  font-size: 1.5rem;
}
.link-nav-tabcontent:hover {
  color: #4a9ae9 !important;
  cursor: pointer;
}
/* DownItem */
.lista-container-menuitem {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.lista-container-menuitem .titulo-menuitem,
.itens-lista-menuitem {
  cursor: pointer;
}
li.titulo-menuitem:hover {
  color: #4a9ae9 !important;
}
.itens-lista-menuitem :hover {
  color: #4a9ae9 !important;
}
.itens-lista-menuitem {
  list-style-type: none;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.texto-itens-menuitem {
  font-size: 14px;
}
.icon-arrow {
  transform: rotate(90deg);
  transition: .4s;
}
/* Media Queries */
@media screen and (max-width: 768px) {
  .titulo-menuitem,
  .link-nav-tabcontent {
    max-width: none;
    width: 100%;
  }
}