/* Variables */
:root {
  --color_grey: #BFBFBF;
  --color_green: #000000;
  --color_dark: #1A1A1A;
  --color_light: #FAFAFA;
  --color_background: #2D3235;
  --default_timer: 987ms;
}
/* Resets */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.button {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-right: 40px;
}
.button:hover .button__border-circle {
  transform: translateX(60px);
}
.button:hover .button__mask-circle {
  clip-path: circle(20px at 85px);
}
.button__text {
  z-index: 1;
  font-size: 14px;
  margin-right: -19px;
  color: var(--color_light);
  letter-spacing: 0.05em;
}
.button__wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.button__arrow {
  left: 35px;
  height: 3px;
  width: 32px;
  display: flex;
  position: absolute;
  align-items: center;
  background-color: var(--color_green);
}
.button__arrow::after {
  content: '';
  width: 0;
  height: 0;
  top: -5px;
  right: -7px;
  position: absolute;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid var(--color_green);
}
.button__border-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid var(--color_grey);
  transition: transform var(--default_timer);
}
.button__mask-circle {
  width: 25px;
  height: 25px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: circle(25px);
  transition: clip-path var(--default_timer);
}
.button__small-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform: translateX(60px);
  background-color: var(--color_green);
}